<template>
    <div class="ContactUs">
        <div class="decoration">
            <img src="@/components/TechnicalSupport/assets/bottle.png" alt="">
        </div>
        <div class="content">
            <div class="cards">
                <div>
                    <div class="icon">
                        <img src="@/components/TechnicalSupport/assets/icon_question.svg" alt="">
                        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    </div>
                    <Str index="tech_support.for_technical_support"/>
                </div>
                <div>
                    <div class="icon">
                        <img src="@/components/TechnicalSupport/assets/icon_info.svg" alt="">
                        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    </div>
                    <Str index="tech_support.for_more_information"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'TechnicalSupport',
}
</script>

<style lang='scss'>

.ContactUs {
    display: flex;
    height: fit-content;
    min-height: calc(100vh - 172px);

    .decoration {
        position: relative;
        width: 20%;
        background-color: $secondary;

        @media(max-width:1119px){
            width: 30%;
        }
        @media(max-width:767px){
            display: none;
        }

        img {
            position: absolute;
            right: -1px;
            height: 100%;
        }
    }

    .content {
        width: 80%;
        padding: 72px 88px;

        @media(max-width:1119px){
            width: 70%;
        }
        @media(max-width:767px){
            width: 100%;
            padding: 24px;
        }

        .cards {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            > div {
                width: 300px;
                min-width: 280px;
                height: 300px;
                margin: 16px;
                padding: 40px 48px;
                background-color: #FAFAFA;
                box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);

                .icon {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 32px;

                    img,
                    svg {
                        text-align: center;
                        width: 80px;
                        height: 80px;
                    }
                }

                p {
                    text-align: center;
                }
            }
        }
    }
}
</style>
